
@media (min-width: 992px) {
  .menu {
    display: none;
  }
}

.logo {
  position: relative;
  display: flex;
  height: 82px;
  width: 1360px;
}
