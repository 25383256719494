.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@import '~antd/dist/antd.css';

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body{

  background-color: #f0f2f5;
}

/*override ant css*/
.ant-layout {
  min-height: 100vh;
  display: flex;
  flex: auto;
  flex-direction:row;
  background:transparent;
}

.ant-layout-sider {
  background: #fff;
}

.ant-menu-inline {
  border: 0;
}

.ant-table-thread {
  background-color: #a2a6e2;
}


.login-form {
  max-width: 350px;
}
.login-form-forgot {
  float: right;
}
.ant-col-rtl .login-form-forgot {
  float: left;
}
.login-form-button {
  width: 100%;
}

.logo {
  margin: 0px;
  width: 200px;
}

.ant-table-thead > tr > th,
td{
  text-align: center;
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
  padding-top: 8px;
  padding-bottom: 8px;

}

.ant-breadcrumb-link {
  font-family:serif;
  font-size: 23px;

}


